import { Layout, Tag, Tooltip, TooltipPosition } from '@loadsmart/loadsmart-ui'
import clsx from 'clsx'
import { MessageRead } from 'common/types/kraken-core/Message'
import DirectionTag from 'atoms/DirectionTag/DirectionTag'
import TransactionTypeTag from 'atoms/TransactionTypeTag/TransactionTypeTag'

export interface MessageDetailHeaderProps {
  message: MessageRead
  className?: string
}

const MessageDetailHeader = ({ message, className }: MessageDetailHeaderProps) => {
  return (
    <Layout.Group align="center" space="s" className={clsx(className)}>
      <Tag className="text-base">
        <Tooltip message="Party" position={TooltipPosition.Bottom}>
          {String(message?.party.name).toLocaleUpperCase()}
        </Tooltip>
      </Tag>
      <DirectionTag direction={message.direction} />
      <Tag className="text-base">
        <Tooltip message="Counter Party" position={TooltipPosition.Bottom}>
          {String(message?.counterparty.name).toLocaleUpperCase()}
        </Tooltip>
      </Tag>
      <TransactionTypeTag transaction_type={message.transaction_type} />
    </Layout.Group>
  )
}

export default MessageDetailHeader
