import { createTheme, ThemeProvider as MaterialUiThemeProvider } from '@mui/material/styles'
import { Themes } from '@loadsmart/loadsmart-ui/dist/theming'
import { ThemeProvider } from 'styled-components'

export interface CustomThemeProviderProps {
  children?: any
}

export const BASE_PIXELS = 16

function rem(valueInPixels: number | string, base = BASE_PIXELS): string {
  return `${parseInt(String(valueInPixels), 10) / base}rem`
}

const customizedLoadsmartTheme = {
  ...Themes.Loadsmart,

  // Spacing
  'space-none': 0,
  'space-2xs': rem('2px'),
  'space-xs': rem('4px'),
  'space-s': rem('6px'),
  'space-m': rem('10px'),
  'space-l': rem('16px'),
  'space-xl': rem('20px'),
  'space-2xl': rem('38px'),
  'space-3xl': rem('46px'),

  // Font-size
  'font-size-1': rem('21px'),
  'font-size-2': rem('19px'),
  'font-size-3': rem('17px'),
  'font-size-4': rem('15px'),
  'font-size-5': rem('13px'),
  'font-size-6': rem('12px'),
}

const customizedMaterialTheme = createTheme({
  zIndex: {
    appBar: 1,
  },
  typography: {
    allVariants: {
      fontFamily: customizedLoadsmartTheme['font-family-default'].toString(),
    },
    // fontSize: 12,
    h1: {
      fontSize: customizedLoadsmartTheme['font-size-1'],
    },
    h2: {
      fontSize: customizedLoadsmartTheme['font-size-2'],
    },
    h3: {
      fontSize: customizedLoadsmartTheme['font-size-3'],
    },
    h4: {
      fontSize: customizedLoadsmartTheme['font-size-4'],
    },
    h6: {
      fontSize: customizedLoadsmartTheme['font-size-6'],
    },
    h5: {
      fontSize: customizedLoadsmartTheme['font-size-5'],
      fontWeight: 'bold',
    },
  },
  palette: {
    primary: {
      main: customizedLoadsmartTheme['color-accent'].toString(),
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: customizedLoadsmartTheme['color-neutral'].toString(),
    },
    text: {
      primary: customizedLoadsmartTheme['text-field-color'].toString(),
    },
  },

  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'inherit',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: customizedLoadsmartTheme['color-primary'],
          borderRadius: customizedLoadsmartTheme['border-radius-s'],
        },
      },
    },
    MuiListItem: {
      defaultProps: {
        style: {
          borderRadius: customizedLoadsmartTheme['border-radius-s'],
          marginTop: '10px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'bolder',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
})

const CustomThemeProvider = ({ children }: CustomThemeProviderProps) => (
  <MaterialUiThemeProvider theme={customizedMaterialTheme}>
    <ThemeProvider theme={customizedLoadsmartTheme}>{children}</ThemeProvider>
  </MaterialUiThemeProvider>
)

export default CustomThemeProvider
